
import React, { useEffect, useState } from 'react'
import { RouteRow, RouteColumn } from '../../layout'
import { Time, TowingRecordJobStatus } from '../../../common/types'
import { TextInput } from '../../layout/form/input'
import { LocationInputMap, toMapData } from '../../layout/form/input/LocationInputMap'
import { ArrivalDateTimeInput } from '../../layout/form/input/DateTimeInput'
import { format } from 'date-fns'
import { CollapseContent } from '../../Collapse/CollapseContent'
import { Button } from '../../Button'
import styled from 'styled-components'
import { ArrivedAtDestinationProps, SosProps } from '../../../containers/TowingRecordPage/components/routes/subforms/FinishRoute/forms/ArrivedForm'

export const SosArrivedAtDestinationForm: React.FunctionComponent<ArrivedAtDestinationProps & SosProps> = ({
  clear,
  getValue,
  setValue,
  action,
  stationId,
  setRouteLocation
}) => {
  const [edit, setEdit] = useState<boolean>(action === 'edit-arrived')

  useEffect(() => {
    if (action === 'edit-arrived') {
      if (!edit) setEdit(true)
    } else {
      if (edit) setEdit(false)
    }
  }, [edit, action, setEdit])

  return (
    <>
      <CollapseContent>
        <RouteRow columns={`2fr 1fr`}>
          <RouteColumn>
            <LocationInputMap
              addressLabel={'Osoite'}
              addressName={`finishRoute.address`}
              cityLabel={`Kaupunki`}
              cityName={`finishRoute.city`}
              coordsName={`finishRoute.coords`}
              zipcodeName={`finishRoute.zipcode`}
              descriptionName={`finishRoute.description`}
              required={edit}
              coordsRequired={edit}
              disabled={!edit}
              getValue={getValue}
              setRouteLocation={setRouteLocation}
              values={toMapData(
                getValue(`finishRoute.address`),
                getValue(`finishRoute.city`),
                getValue(`finishRoute.coords`),
                getValue(`finishRoute.zipcode`)
              )}
              setValue={setValue}
              includeNameInAddress={false}
              stationId={stationId}
              clear={clear}
            />
          </RouteColumn>
          <RouteColumn>
            <ArrivalDateTimeInput
              label="Saapumisaika"
              name={`finishRoute`}
              customOnChange={() => {
                const value = (format(new Date(), 'HH:mm') as unknown) as Time
                setValue('finishRoute.arrivalDate', new Date())
                setValue('finishRoute.arrivalTime', value)
              }}
              required={edit}
              disabled={!edit}
              showCurrentTimeBtn
            />
          </RouteColumn>
        </RouteRow>
        <RouteRow columns={`1fr 1fr 1fr`}>
          <RouteColumn>
            <TextInput name="finishRoute.description" label="Korjaamon nimi" disabled={!edit} />
          </RouteColumn>
        </RouteRow>
      </CollapseContent>
    </>
  )
}

export const arrivedAtDestinationEditableJobStatuses = [
  TowingRecordJobStatus.arrived_at_destination,
  TowingRecordJobStatus.finished_at_destination,
]

export const CustomButton = styled(Button)`
  margin-right: 1rem;
`
